import React, { useState, useEffect } from 'react'
import GoogleMap from 'google-map-react'
import MapMarker from './MapMarker/MapMarker'
import useWindowSize from '../../hooks/useWindowSize'
import { mapStyles } from './mapStyles'
import useScript from '../../hooks/useScript'

function Map() {
  const { width } = useWindowSize()

  const config = {
    center: {
      lat: 55.751244,
      lng: 37.618423,
    },
    defaultCenter: {
      lat: 55.751244,
      lng: 37.618423,
    },
    defaultZoom: 5,
    draggable: false,
    options: { styles: mapStyles },
  }

  // const [markers, setMarkers] = useState([{ lat: 50.431759, lng: 30.517023 }])

  // useEffect(() => {
  //   db.collection('shops')
  //     .get()
  //     .then((docs) => {
  //       const spots = []
  //       docs.forEach((doc) => {
  //         const shopData = doc.data()
  //         shopData.spots.forEach((spot) => {
  //           if (spot.isActive && spot.lat && spot.lng) {
  //             spots.push({ ...spot, avatar: shopData.avatar, shop: shopData })
  //           }
  //         })
  //       })

  //       setMarkers(spots)
  //     })
  // }, [])

  return (
    <div className="Map">
      <GoogleMap
        bootstrapURLKeys={{
          key: 'AIzaSyAnzL3sW-tHyBhg2cyBny1G9M90x7xms6g',
          language: 'uk',
        }}
        {...config}
      >
        {/* {markers.map((m) => (
          <MapMarker {...m} />
        ))} */}
      </GoogleMap>
    </div>
  )
}

export default Map
