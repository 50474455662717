import React from 'react'
import Doctors from '../../components/Doctors/Doctors'
import './DoctorsPage.sass'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'

function DoctorsPage() {
  return (
    <>
      <div className="DoctorsPage">
        <Doctors />
      </div>
      <RequestConsultation />
      <FeedsCarousel />
    </>
  )
}

export default DoctorsPage
