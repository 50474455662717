import React from 'react'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import './SitePage.sass'
import Top from '../../components/Top/Top'

function SitePage({ children }) {
  return (
    <>
      <Top />
      <Nav />
      {children}
      <Footer />
    </>
  )
}

export default SitePage
