import React from 'react'
import { HashLink, NavHashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import { Link } from 'react-router-dom'

const SiteNavLink = React.forwardRef(
  ({ title, type = '', path = null, isActive, onClick }, ref) =>
    path ? (
      <HashLink
        to={path}
        className={[
          'Site-NavLink',
          ...(isActive ? ['Site-NavLink_theme_active'] : []),
        ].join(' ')}
        smooth
        activeClassName="Site-NavLink_theme_active"
        scroll={(el) => scrollWithOffset(el, -64)}
      >
        {title}
      </HashLink>
    ) : type === 'button' ? (
      <span onClick={onClick} ref={ref} className="Site-NavLink">
        {title}
      </span>
    ) : (
      <span
        ref={ref}
        className={[
          'Site-NavLink',
          ...(type === 'dropdown' ? ['Site-NavLink_theme_dropdown'] : []),
          ...(isActive ? ['Site-NavLink_theme_active'] : []),
        ].join(' ')}
      >
        {title}
        {type === 'dropdown' && (
          <Icon name="angle-down" helperClass="Site-NavLink-Arrow" />
        )}
      </span>
    )
)

export default SiteNavLink
